import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

import * as Styled from './styles/RecentAdvanceArticles.styles'
import { linkResolver } from '../../util/linkResolver'
import DEFAULT_THUMBNAIL from '../../images/logo-over-blue-70x70.png'

const RECENT_ARTICLES_QUERY = graphql`
  {
    prismicAdvanceLandingPage {
      id
      data {
        recent_advance_articles {
          recent_advanced_article {
            url
            uid
            id
            document {
              ... on PrismicAdvanceArticle {
                id
                last_publication_date
                type
                uid
                url
                first_publication_date
                data {
                  publish_date
                  hero_image {
                    alt
                    url
                    thumbnails {
                      recent_article_list {
                        url
                      }
                    }
                  }
                  header {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const RecentAdvanceArticles = () => (
  <StaticQuery
    query={`${RECENT_ARTICLES_QUERY}`}
    render={data => {
      const nodes =
        data.prismicAdvanceLandingPage?.data?.recent_advance_articles?.map(
          r => r.recent_advanced_article
        ) ?? null

      nodes
        .sort((a, b) => {
          const dateA = new Date(a.document.data.publish_date)
          const dateB = new Date(b.document.data.publish_date)
          return dateA - dateB
        })
        .slice(0, 3)

      if (!nodes) {
        return null
      }

      return (
        <Styled.RecentArticlesColumn>
          <Styled.SectionTitle>Recent Advance Articles</Styled.SectionTitle>
          {/* <pre>{ JSON.stringify(nodes, false, 2)}</pre> */}
          {nodes.map(node => (
            <Styled.ArticleLink key={node.id} to={linkResolver(node.document)}>
              <Styled.ArticleCircleImage
                src={
                  node.document?.data?.hero_image?.thumbnails
                    ?.recent_article_list?.url
                    ? node.document.data.hero_image.thumbnails
                        .recent_article_list.url
                    : DEFAULT_THUMBNAIL
                }
                alt={
                  node.document?.data?.hero_image?.recent_article_list?.alt
                    ? node.document.data.hero_image.alt
                    : 'NPF Logo'
                }
              />
              <Styled.ArticleHeading>
                {node.document?.data?.header?.text ?? ''}
              </Styled.ArticleHeading>
            </Styled.ArticleLink>
          ))}
        </Styled.RecentArticlesColumn>
      )
    }}
  />
)

export default RecentAdvanceArticles
