import React, { useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { navigate } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

import { DEVICE } from '../../util/constants'
import * as Styled from './styles/AdvanceArticleHero.styles'
import { HeroGraphicAdvance, HcpdBackArrow } from '../Icons'

const BackButton = () => (
  <Styled.BackButton onClick={() => navigate('/advance')}>
    <HcpdBackArrow />
    <span>Read Advance Online</span>
  </Styled.BackButton>
)

const AdvanceArticleHero = ({
  header,
  heroText,
  heroImage,
  showPageTitle = true,
  showBackButton = true,
}) => {
  const tabletAndUp = useMediaPredicate(DEVICE.tabletL)
  const [hasImage] = useState(!!heroImage)
  return (
    <Styled.GridContainer>
      {showBackButton && (
        <Styled.BackButtonContainer>
          <BackButton />
        </Styled.BackButtonContainer>
      )}
      <Styled.AdvanceArticleHero>
        <Styled.HeroGraphicContainer>
          <HeroGraphicAdvance />
        </Styled.HeroGraphicContainer>
        <Styled.TextColumn>
          <Styled.PageTitle isMobile={true}>
            {showPageTitle ? 'Advance Online' : '\u00A0'}
          </Styled.PageTitle>
          <Styled.HeroTextRegion>
            {hasImage && !tabletAndUp && (
              <Styled.HeroImage
                width={311}
                height={158}
                src={heroImage.thumbnails.mobile.url}
                alt={heroImage.alt}
              />
            )}
            <Styled.CenteredTextColumn hasImage={hasImage}>
              <Styled.PageTitle isMobile={false}>
                {showPageTitle ? 'Advance Online' : '\u00A0'}
              </Styled.PageTitle>
              <Styled.HeroTitle>
                <PrismicRichText field={header.richText} />
              </Styled.HeroTitle>
              <Styled.HeroText>
                <PrismicRichText field={heroText.richText} />
              </Styled.HeroText>
            </Styled.CenteredTextColumn>
            {hasImage && tabletAndUp && (
              <Styled.HeroImage
                width={367}
                height={300}
                src={heroImage.url}
                alt={heroImage.alt}
              />
            )}
          </Styled.HeroTextRegion>
        </Styled.TextColumn>
      </Styled.AdvanceArticleHero>
    </Styled.GridContainer>
  )
}

export default AdvanceArticleHero
