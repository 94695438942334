import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'

import Layout from '../components/Layout'
import AdvanceArticleHero from '../components/Heroes/AdvanceArticleHero'
import AdvanceArticleContent from '../components/AdvanceArtlcleContent'
import GoogleAdBlock from '../components/ContentBlocks/GoogleAdBlock'

const AdvanceArticle = ({ data }) => {
  const doc = data.prismicAdvanceArticle
  if (!doc) {
    return null
  }
  const settingsNode = data.prismicGeneralSiteSettings.data
  const interstitialId =
    settingsNode?.advanced_article_interstitial_ad?.document.id
  const headerText = doc.data.header ? doc.data.header : ''
  const heroText = doc.data.intro_text ? doc.data.intro_text : ''
  const twitterImage = doc.data.twitter_image?.url
    ? doc.data.twitter_image
    : doc.data.hero_image?.url
    ? doc.data.hero_image
    : null
  const facebookImage = doc.data.facebook_image?.url
    ? doc.data.facebook_image
    : doc.data.hero_image?.url
    ? doc.data.hero_image
    : null
  const seoTitle = doc.data.meta_title?.text
    ? doc.data.meta_title.text
    : doc.data.header?.text
    ? doc.data.header.text
    : null
  const seo_embed_code = doc.data.seo_embed_code?.text ?? null
  const uid = doc.uid
  const isExcluded =
    settingsNode.excluded_documents.filter(
      item => item.excluded_document?.uid === uid
    ).length > 0

  return (
    <>
      <SEO
        seo={{
          description: doc.data.meta_description,
          keywords: doc.data.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
          isExcluded: isExcluded,
        }}
      />
      <Layout>
        <AdvanceArticleHero
          header={headerText}
          heroText={heroText}
          heroImage={doc.data.hero_image}
        />
        <AdvanceArticleContent doc={doc} />
        <GoogleAdBlock id={interstitialId} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query AdvanceArticleQuery($id: String) {
    prismicGeneralSiteSettings {
      data {
        excluded_documents {
          excluded_document {
            uid
          }
        }
        advanced_article_interstitial_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
      }
    }
    prismicAdvanceArticle(id: { eq: $id }) {
      uid
      id
      last_publication_date
      data {
        header {
          richText
          text
        }
        hero_image {
          alt
          url
          thumbnails {
            landing_page_featured {
              url
            }
            mobile {
              url
            }
            recent_article_list {
              url
            }
          }
        }
        intro_text {
          richText
        }
        publish_date
        add_last_updated_date_label
        meta_description
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        facebook_image {
          url
        }
        twitter_image {
          url
        }
        body {
          ... on PrismicAdvanceArticleDataBodyAccordionBlock {
            id
            slice_type
            primary {
              section_header {
                text
              }
              bottom_padding_adjustment
            }
            items {
              content {
                richText
              }
              block_header {
                text
              }
            }
          }
          ... on PrismicAdvanceArticleDataBodyAdvancedImageBlock {
            id
            slice_type
            primary {
              layout_option
              text_content {
                richText
              }
              cta_text {
                text
              }
              cta_link {
                uid
                link_type
                id
                type
                url
              }
              header_text {
                text
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyAuthorBlock {
            id
            slice_type
            primary {
              author {
                document {
                  ... on PrismicGuestAuthor {
                    id
                    type
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicNpfAuthor {
                    id
                    type
                    uid
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      bio {
                        richText
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyBasicImageBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              image_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyBodyContent {
            id
            slice_type
            primary {
              body_copy {
                richText
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyCalendarOfEventsBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              layout_style
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyCustomForm {
            id
            slice_type
            primary {
              form_title
              form_body {
                richText
              }
              form_embed_code {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyEventBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
            items {
              event {
                id
                document {
                  ... on PrismicEvent {
                    data {
                      end_date
                      event_date
                      event_city
                      event_state
                      end_time
                      start_time
                      event_venue
                      event_address_line_1
                      event_address_line_2
                      event_image {
                        alt
                        url
                        thumbnails {
                          mobile {
                            url
                          }
                        }
                      }
                      event_title {
                        text
                      }
                      event_description {
                        text
                      }
                      event_link {
                        id
                        link_type
                        uid
                        url
                        type
                      }
                      event_link_text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAdvanceArticleDataBodyGoogleAd {
            id
            slice_type
            primary {
              google_ad_tag {
                document {
                  ... on PrismicGoogleAdTag {
                    id
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyGridBlock {
            id
            slice_type
            primary {
              layout_type
              bottom_padding_adjustment
            }
            items {
              cta_link_text
              cta_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              summary {
                richText
              }
              title {
                text
              }
            }
          }
          ... on PrismicAdvanceArticleDataBodyIconTextBlock {
            id
            slice_type
            primary {
              header {
                richText
              }
              body_text {
                richText
              }
              bottom_padding_adjustment
            }
            slice_type
            items {
              cta_text
              item_title {
                richText
              }
              item_icon {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              item_body_text {
                richText
              }
              cta_link {
                id
                url
                uid
                type
                link_type
              }
            }
          }
          ... on PrismicAdvanceArticleDataBodySponsorsBlock {
            id
            slice_type
            items {
              sponsor_logo {
                alt
                url
              }
              sponsor_link {
                url
                uid
                link_type
                id
              }
            }
            primary {
              intro_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyStatisticsBlock {
            id
            slice_type
            primary {
              citation
              statistic_prompt
              header {
                text
              }
              subhead {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAdvanceArticleDataBodyVideoBlock {
            id
            slice_type
            primary {
             caption {
                text
              }
              schema_publish_date
              schema_video_description
              video_duration_hours
              video_duration_minutes
              video_duration_seconds
              transcription_link {
                url
                uid
                type
                link_type
              }
              video_title {
                text
              }
              video_thumbnail {
                url
              }
              video_link {
                embed_url
              }
              bitmovin_stream_id
              bottom_padding_adjustment
            }
          }
        }
      }
    }
  }
`

export default AdvanceArticle
