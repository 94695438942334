import styled from 'styled-components'
import { DEVICE } from '../../util/constants'
import GoogleAdBlock from '../ContentBlocks/GoogleAdBlock'
import { GriddedContentBlock } from '../styles/Utility.styles'

export const PageGrid = styled(GriddedContentBlock)`
  margin-top: 10px;
`

export const PageColumnGrid = styled.div`
  grid-column: 2 / 12;
  display: grid;
  grid-template-columns: 1fr;

  @media ${DEVICE.desktopS} {
    grid-template-columns: repeat(6, 1fr);
    column-gap: 40px;
    margin-bottom: 50px;
  }
`

export const ArticleColumn = styled.div`
  @media ${DEVICE.desktopS} {
    grid-column: 1 / 5;
  }
`
export const RecentArticlesColumn = styled.div`
  @media ${DEVICE.desktopS} {
    grid-column: 5 / 7;
  }
`

export const ArticleDateField = styled.span`
  /* grid-column: 2 / 3; */
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.78;
  color: var(--content-background-dark-blue);
`

// Google Ads
export const ToplineGoogleAd = styled(GoogleAdBlock)`
  margin-bottom: 42px;
`

export const SidebarGoogleAd = styled(GoogleAdBlock)`
  margin-top: 42px;
`
