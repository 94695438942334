import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { Link } from 'gatsby'

export const RecentArticlesColumn = styled.div`
  display: none;

  @media ${DEVICE.laptopG} {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
  }
`

export const SectionTitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2.29;
  text-transform: uppercase;
  color: var(--highlight-blue);
  padding-bottom: 14px;
`

export const ArticleLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
  text-decoration-line: none;

  :hover {
    text-decoration-line: underline;
  }

  :last-of-type {
    padding-bottom: 0;
  }
`

export const ArticleCircleImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`

export const ArticleHeading = styled.h5`
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.375;
  color: var(--content-background-dark-blue);
  padding-left: 24px;
  text-decoration-line: none;
`
